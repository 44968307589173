import React, { Component } from "react";
import { Link } from "react-router-dom";
import './Checkout.css';
import Basket from './Basket';

class Checkout extends Component {
    constructor() {
        super();
        this.state = { price: 27, totalProducts: 0, size: '', sort: '', cartItems: [], products: [], filteredProducts: [], preco: 35, endereco: '', pegarEncomenda: '0' };
        this.generatePagseguroLink = this.generatePagseguroLink.bind(this);
        this.chamarNoWhatsapp = this.chamarNoWhatsapp.bind(this);
    }

     openInNewTab(url) {
        // var win = window.open(url, '_blank');
        // win.focus();

        window.location.replace(url);
    }

    componentWillMount() {

        let cartItems = JSON.parse(localStorage.getItem('cartItems'));

        let totalProducts = 0;
        let price = 27;

        for(let i = 0; i < cartItems.length; i++) {
          totalProducts += cartItems[i].count;   
        }

        if(totalProducts >= 10) {
          price = 25;
        }

        if(totalProducts >= 20) {
          price = 24;
        }

        if(totalProducts >= 30) {
          price = 23;
        }
        



        if (cartItems) {
          this.setState({ cartItems: cartItems,
          preco: parseFloat(localStorage.getItem('preco')),
          pegarEncomenda: localStorage.getItem('pegarencomenda'),
          endereco: localStorage.getItem('endereco'),
          totalProducts, 
          price,
        });
        }
    
        fetch('db.json').then(res => res.json()).then(data => data.products)
          .then(data => {
            this.setState({ products: data });
          });
      }

      generatePagseguroLink() {
        //   console.log('Dadddoos', this.state.cartItems);
          console.log('Dadddoos', JSON.stringify(this.state.cartItems));

          // fetch('http://localhost:9890/generate-payment-new', 
          fetch('https://back-end-buffet-ceia.herokuapp.com/generate-payment-new', 
          {
              method: 'POST',
              headers: {'Content-Type':'application/json'},
              body: JSON.stringify({data: this.state.cartItems, preco: parseFloat(this.state.preco).toFixed(2), endereco: this.state.endereco, frete: this.state.pegarEncomenda}),
          }).then(res => res.json())
          .then(data => {
            this.openInNewTab(data.link);
          })
          .catch((error) => {
              console.log(error);
              alert('Erro ao abrir o pagseguro por favor opte por outra forma de pagamento');
          });
      }

      chamarNoWhatsapp() {
          let txt = 
          `
          Ola, acessei o site de marmitas fit e gostaria de fazer o seguinte pedido: 
          `;

          this.state.cartItems.forEach((item) => {
            txt += ' - ' + item.count + ' ' + item.title + ': R$ ' + this.state.price + ' -------';
          });

          if(this.state.endereco) {
            txt += ' ------ Endereço: ' + this.state.endereco;

          } else {
            txt += ' ------ Endereço: Não informado' ;

          }


          if(this.state.pegarEncomenda == '1') {
            txt += '--- Irei buscar a ceia no endereco de vocês.'
        } else {

          if(isNaN(this.state.preco)) {
            txt += ' ========== Frete: Não calculado' 
          } else {
            txt += ' ========== Frete: ' + this.state.preco

          }

          }
          this.openInNewTab("https://api.whatsapp.com/send?phone=5511940247608&text=" + txt + "&source=&data='");
      }

      handleRemoveFromCart = (e, product) => {
        this.setState(state => {
          const cartItems = state.cartItems.filter(a => a.id !== product.id);
          localStorage.setItem('cartItems', JSON.stringify(cartItems));
          return { cartItems: cartItems };
        })
      }

    render() {
        return (
            <div class="checkout">
                {/* <h1>Salles Buffet - Orçamento de natal/ano novo personalizado</h1>
                 */}

                 <div className="logo">
                    <img className="chapeu" src="./salles_buffet_logo.png" />
                </div>  


                <div
                    className="logo-legend"
                >
                    Uma iniciativa de um aluno <img src="./link_logo.png" />
                </div>


                <div className="titulo">
                    {/* <h4>Ceias sujeito a lotação</h4>
                    <h5>{this.state.countdown}</h5> */}
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Basket totalProducts={this.state.totalProducts} pegarEncomenda={this.state.pegarEncomenda} buttonFinalizar={false} cartItems={this.state.cartItems} handleRemoveFromCart={this.handleRemoveFromCart} preco={this.state.preco} />
                    </div>
                </div>

                <ul>

                </ul>
                <div class="voltar">


                <a className="pagar-pagseguro" href="#" onClick={this.chamarNoWhatsapp}>
                    Concluir via Whatsapp <img src="./whatsapp.png" />
                </a>
                
                <p className="ou-entao">
                    Ou então
                </p>

                <a className="pagar-pagseguro" href="#" onClick={this.generatePagseguroLink}>
                    Pagar com PagSeguro
                </a>

                

                <p className="numero-contato">
                    {/* Caso queira finalizar a compra por ligação, basta ligar para (11)985-595-639 */}
                </p>


                <Link className="continuar-comprando" to="list-products">
                    Continuar comprando
                </Link>

                    
                </div>

            </div>
        )
    }
}


export default Checkout;