import React, { Component } from 'react';
import util from '../util'
import { Link } from "react-router-dom";
import './Basket.css';

export default class Basket extends Component {
    render() {
        const { cartItems, preco, totalProducts } = this.props;

        return (
            <div className="carrinho">
                {cartItems.length === 0
                ? (<div className="info-carrinho">
                    <h3>Seu carrinho esta vazio!</h3>
                <h4>Seus itens aparecerão aqui quando forem adicionados ao carrinho</h4>
                <h5 className="observacao-kg">Você poderá esclarecer qualquer dúvida antes de concluir o pedido nas próximas telas</h5>
                </div>) :
                    <div className="info-carrinho">Você tem {totalProducts} marmitas no seu carrinho. <hr /></div>
                }
                {cartItems.length > 0 &&
                    <div className="itens-carrinho">
                        <ul style={{ marginLeft: -25 }}>
                            {cartItems.map(item => {

                                let price = 27;

                                // Meio burro fazer esses calculos aqui pois é reprocessado em toda renderização mas wathever é um calculo simples
                                if(totalProducts >= 10) {
                                    price = 25
                                }

                                if(totalProducts >= 20) {
                                    price = 24
                                }

                                if(totalProducts >= 30) {
                                    price = 23
                                }

                                return <li key={item.id}>
                                <b>{item.title}</b>
                                <button style={{ float: 'right' }} className="btn btn-danger btn-xs"
                                    onClick={(e) => this.props.handleRemoveFromCart(e, item)}>X</button>
                                <br />
                                {item.count} x {util.formatCurrency(price)}
                            </li>
                            })
                            }
                        </ul>

                        <p className="valor-total"><h4>Carrinho: {util.formatCurrency(cartItems.reduce((a, c) => {
                            let price = 27;

                            if(totalProducts >= 10) {
                                price = 25
                            }

                            if(totalProducts >= 20) {
                                price = 24
                            }

                            if(totalProducts >= 30) {
                                price = 23
                            }

                            return a + price * c.count;
                        }, 0))}
                        </h4>

                        {localStorage.getItem('preco') && <h5>
                            Frete: R${localStorage.getItem('preco')}
                        </h5>}

                        {/* {
                            this.props.pegarEncomenda && this.props.pegarEncomenda == '1' ?
                                <p className="valor-total">R$0 - Pegar encomenda</p>
                            :
                                <p className="valor-total"><h6 class="entrega">Taxa de entrega: R${preco ? preco : 35}</h6> </p>
                        } */}
                         </p>
                        
                       
                        {
                            this.props.buttonFinalizar ?
                            <Link className="botao-finalizar" to="/shipping">Finalizar Compra</Link>
                            // <Link className="botao-finalizar" to="/checkout">Finalizar Compra</Link>
                            : ''
                        }
                        
                    </div>
                }
            </div>
        )
    }
}
